// Libs
import React, { Component } from 'react';
import styled from 'styled-components';

// Assets
import jojoProfile from '../../assets/Group 3983@2x.png'

const Container = styled.div`
  height: 100%;
  padding: 7rem 0;
  min-height: 600px;
  background: var(--violet);
`;

const ChatBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 450px;
  margin: 0 auto;
  background-color: #FFF;
  border-radius: 36px;
`;

const Header = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const HeaderTexts = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding-left: 5em;
  justify-content: space-between;

  @media only screen and (max-width: 754px) {
    padding-left: 0em;
    justify-content: center;
  }

  @media only screen and (max-width: 490px) {
    justify-content: flex-end;
    padding-right: 1em;
  }
`;

const Title = styled.h2`
  color: #ffffff;
`;

const Profile = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

const Message = styled.span`
  display: inline-block;
  margin-bottom: 2rem;
  color: var(--primaryColor);
  font-size: 1.5rem;
`;

const ButtonsContainer = styled.div`
  width: 20%;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  margin: 1rem 0;
  border: none;
  border-radius: 22px;
  padding: .5rem;
  outline: none;
  color: #FFF;
  cursor: pointer;
  background-color: var(--primaryColor);
`;

class FakeChat extends Component {
  setSignIn = () => {
    this.props.setModal({
      isOpen: true,
      type: 'signin'
    });
  }

  setSignup = () => {
    this.props.setModal({
      isOpen: true,
      type: 'signup'
    });
  }

  ResetPassword = () => {
    this.props.setModal({
      isOpen: true,
      type: 'resetpasswd'
    });
  }

  render() {
    return (
      <Container>
        <Header>
          <HeaderTexts>
            <Title>Fale com a Jojô</Title>
          </HeaderTexts>
        </Header>
        <ChatBody>
          <Profile
            src={jojoProfile}
          />
          <Message>
            Cadastre-se ou acesse sua conta para iniciar:
          </Message>
          <ButtonsContainer>
            <Button
              onClick={this.setSignIn}
            >
              Entrar
            </Button>
            <Button
              onClick={this.setSignup}
            >
              Criar Conta
            </Button>

            <Button
              onClick={this.ResetPassword}
            >
              Esqueci a Senha
            </Button>
          </ButtonsContainer>
        </ChatBody>
      </Container>
    );
  }
}

export default FakeChat;
