import React, { Component, Fragment } from 'react';

// Sections
import About from './About';
import Chat from './Chat';
import FakeChat from './FakeChat';
import Footer from './Footer';
import Header from '../../components';
import Intro from './Intro';

// Components
import Modal from '../../components/Modal';

class Home extends Component {
  state = {
    auth: {
      isAuthenticated: false,
    },
    modal: {
      isOpen: false,
      type: ''
    }
  };

  setModal = (modal) => {
    this.setState({
      modal: {
        ...modal,
      },
    });
  }

  handleAuth = (auth) => {
    this.setState({
      auth,
      modal: {
        isOpen: false,
        type: ''
      }
    });
  }

  renderChat = () => {
    if(this.state.auth.isAuthenticated) {
      return (
        <Chat />
      )
    }

    return (
      <FakeChat
        setModal={this.setModal}
      />
    );
  }

  renderModal = () => {
    if (this.state.modal.isOpen) {
      return (
        <Modal
          modal={this.state.modal}
          setModal={this.setModal}
          handleAuth={this.handleAuth}
        />
      )
    }

    return null;
  }

  render() {
    return (
      <Fragment>
      <Header />
      <Intro />
      {this.renderChat()}
      <About />
      <Footer />
      {this.renderModal()}
    </Fragment>
    )
  }
};

export default Home;