import React, { Fragment } from 'react';
import Home from './pages/Home';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import ReactDOM from 'react-dom';
import firebase from 'firebase';

import './index.css';

import Private from './private';

// import OrderCenter from './pages/OrderCenter';
import ResetPassword from './pages/OrderCenter/ResetPassword';
import Login from './pages/OrderCenter/Login';
import Managment from './pages/OrderCenter/Management';
import Logout from './pages/OrderCenter/Logout';

const firebaseConfig = {
  apiKey: "AIzaSyBHXS3P4jACCOa9xLuguDUe096c5HANEok",
  authDomain: "jojo-vnw.firebaseapp.com",
  databaseURL: "https://jojo-vnw.firebaseio.com",
  projectId: "jojo-vnw",
  storageBucket: "jojo-vnw.appspot.com",
  messagingSenderId: "923109351067",
  appId: "1:923109351067:web:51bd9b4160b476bd63f03e"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {process.env.REACT_APP_PAGE === 'user' && (
        <Fragment>
          <Route
            exact
            path="/"
            component={(props) => (
              <Home {...props} />
            )}
          />
          <Route>
            <Redirect to='/' />
          </Route>
        </Fragment>
      )}
      {process.env.REACT_APP_PAGE === 'admin' && (
        <Fragment>
          <Route
            exact
            path="/login"
            component={(props) => (
              <Login {...props} />
            )}
          />
          <Route
            exact
            path="/logout"
            component={(props) => (
              <Logout {...props} />
            )}
          />
          <Route
            exact
            path="/admin"
            component={(props) => (
              <Private {...props}>
                {() => (
                  <Managment />
                )}
              </Private>
            )}
          />
          <Route
            exact
            path="/reset-password"
            component={(props) => (
              <ResetPassword {...props} />
            )}
          />
          <Route>
            <Redirect to='/login' />
          </Route>
        </Fragment>
      )}
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
