// Libs
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

class Private extends Component {
	constructor(props) {
		super(props);

		this.state = {
			validated: false,
		};
	}

	getToken = () => {
		const credentials = window.localStorage.getItem('jojo_creds');

		if (credentials) {
			return true;
		}

		return false;
	}

	render() {
		return (
			<Fragment>
				{this.getToken() 
					? this.props.children(this.props)
					: <Redirect to="/login" />
				}
			</Fragment>
		);
	}
}

export default Private;