// Libs
import React, { Component } from 'react';
import styled from 'styled-components';
import { Text, Line } from '../../components'
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';
import whatsapp from '../../assets/icons/whatsapp.svg';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 40px 0 40px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 362px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const SocialMedias = styled.div`
  width: auto;
  margin: 20px 0 20px 0;
`;

const MediaIcon = styled.img`
  filter: var(--greenFilter);
  margin: 5px;
  width: 29px;
  cursor: pointer;
`;


class About extends Component {
  render() {
    return (
      <Container>
        <Content>
          <div>
            <Title>SOBRE</Title>
            <Line margin={'0 0 20px 0'}/>
          </div>
          <Text>Jojô Serviços surgiu com o propósito de solucionar as dificuldades pessoais de cada cliente. Dessa forma, os serviços oferecidos são capazes de facilitar a vida pessoal e profissional dos usuários.</Text>
          <SocialMedias>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://web.facebook.com/JojoServicos/?_rdc=1&_rdr"
            >
              <MediaIcon src={facebook} />
            </a>
            
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/jojoservicos/"
            >
              <MediaIcon src={instagram} />
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://api.whatsapp.com/send?phone=5521992093995&text=Olá, Tudo bem?"
            >
              <MediaIcon src={whatsapp} />
            </a>
          </SocialMedias>

        

        </Content>

      </Container>
    );
  }
}

export default About;