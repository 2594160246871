// Libs
import React, { Component } from 'react';
import styled from 'styled-components';
import { Text, Line, backgroundCircles } from '../../components'
import profile from '../../assets/Screen Shot 2020-09-11 at 16.21.48.png';
import arrow from '../../assets/icons/arrow.svg'

// Components
import Slider from './Slider';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 209px 0 0 0;
  overflow: none;
`;

const Title = styled.h1`
  font-size: 30px;
`;

const Content = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;

  @media only screen and (max-width: 600px) {
    margin-top: 100px;
  }

`;

const Profile = styled.div`
  background-image: url("${props => props.profile}");
  background-color: silver;
  background-size: cover;
  width: 176px;
  height: 176px;
  border-radius: 50%;
  margin: 0 0 20px 0;
`;

const Button = styled.button`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
    padding: 10px 20px 10px 20px;
    background: var(--green);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    margin-top: 50px;
  }
`;

const Arrow = styled.img`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
    filter: var(--greenFilter);
    margin-top: 10px;
    width: 18px;
  }
`;

class Intro extends Component {

  render() {
    return (
      <Container>
        {backgroundCircles()}
        <Content>
          <Profile profile={profile}/>
          <Title>Pode ficar tranquilo, <br/> você encontrou a Jojô!</Title>
          <Line margin={'10px 0 10px 0'}/>
          <Text>A Jojô ajuda você a superar os <br/>contratempos burocráticos em <br/>poucos passos.</Text>

          <Button as="div">
            Veja como
          </Button>
          <Arrow src={arrow} />
        </Content>

        <Slider />


      </Container>
    );
  }
}

export default Intro;