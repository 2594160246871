// Libs
import React, { Component } from 'react';
import rg from '../../assets/rg.png';
import doc from '../../assets/doc.svg';
import suitcase from '../../assets/suitcase.svg';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-itens: center;
  justify-content: center;
  flex-direction: column;
  margin: 200px 0 104px 0;
  min-height: 400px;
`;

const ControlButton = styled.button`
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px;
  background: ${props => props.selected ? "#505050" : "#A2A2A2"};
  cursor: pointer;
`;

const RowButton = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 40px;
`;

const Img = styled.img`
  width: 116px; 
`;

const Slide = styled.div`
  text-align: center;
  min-height: 300px;
  max-width: 340px;
`;

const Title = styled.h2`
  margin: 40px 0 20px 0;
  font-size: 28px;
`;

class Slider extends Component {
  state = {
    selected: 0
  }

  content = [
    {
      title: "Agende seus documentos",
      text: "Carteira de Trabalho, Carteira de identidade, Título de eleitor, Boletim de ocorrência e Alistamento militar",
      img: rg
    },
    {
      title: "Gerencie seu negócio",
      text: "Abertura, encerramento e regularização de MEI",
      img: doc
    },

    {
      title: "Encontre seu emprego",
      text: "Currículos, indicações e encaminhamentos jovens aprendizes",
      img: suitcase
    },
  ]

  slide = (slideContent, selected) => {
    return(
      <Slide>
        <Img src={slideContent[selected].img}></Img>
        <Title>{slideContent[selected].title}</Title>
        <p>{slideContent[selected].text}</p>
      </Slide>
    );
  }

  isSelected = (buttonValue) => {
    return this.state.selected === buttonValue;
  }

  render() {
    return (
      <Container>
        {
          this.slide(this.content, this.state.selected)
        }
        <RowButton>
          <ControlButton
            selected={this.isSelected(0)}
            onClick={() => this.setState({selected: 0})}
          />
          <ControlButton
            selected={this.isSelected(1)}
            onClick={() =>
            this.setState({selected: 1})}
          />
          <ControlButton
            selected={this.isSelected(2)}
            onClick={() =>
            this.setState({selected: 2})}
          />
        </RowButton>
      </Container>
    );
  }
}

export default Slider;