import React from 'react';
import Header from '../../../components';
import styled from 'styled-components';
import firebase from 'firebase';
import { Link } from 'react-router-dom';


const Body = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 0;
  background: transparent linear-gradient(180deg, #2E7BB4 0%, #306BAD 12%, #3260A8 32%, #3358A4 100%) 0% 0% no-repeat padding-box;
  background-color: var(--violet);
  min-height: 100vh;
  width: 100%;

  @media only screen and (max-width: 768px) {
    width: 96%;
    padding: 100px 2% 0 2%;
  }
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;

  h1{
    color: #fff;
  }
`;

const FilterContainer = styled.section`

  width: 100%;
  margin-bottom: 5rem;
  max-width: 1280px;

  @media only screen and (max-width: 768px) {
    display: ${props => props.removeMobile ? 'none': 'block'};
  }
`;

const Section = styled.section`
  width: 100%;
  max-width: 1280px;

  @media only screen and (max-width: 768px) {
    display: ${props => props.removeMobile ? 'none': 'block'};
  }
`;

const TableContainer = styled.div`
  overflow: auto;
  margin-bottom: 10vh;
`;

const Table = styled.table`
  padding: 10px 0 10px 0;
  background: #3358A4;
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
  border-spacing: 15px;
`;

const Tr = styled.tr`
`;

const Th = styled.th`
  text-align: left;
  font-size: 14px;
  padding: 5px 0 5px 5px;
  width: 100px;
  color: #ffffff;
  min-width: 100px;
  background-color: #3358A4;
`;

const Td = styled.td`
  padding-left: 5px;
  background-color: #fff;
  font-size: 12px;
  min-width: 100px;
`;

const LogoutButton = styled.button`
  padding: 10px;
  position: fixed;
  border: none;
  right: 0;
  bottom: 0;
  margin: 1%;
  background-color: var(--green);
  color: #fff;
  border-radius: 10px;
  box-shadown: 10px 10px silver;
  cursor: pointer;
`;

const Filter = styled.div`
  width: 30%;
`;

const FilterButton = styled.button`
  text-align: left;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  background-color: #3358A4;
  font-size: 12px;
  cursor: pointer;
  border: none;
  color: #fff;
  underline: none;
  border-radius: 10px 10px 10px 0;
`;

const FilterOptions = styled.div`
  border: 2px solid #3358A4;
  position: absolute;
  max-width: 300px;
  background-color: #fff;
  border-radius: 0 10px 10px 10px;
  padding: 10px;
`;

const FilterP = styled.p`
  font-size: 0.8em;
`
const FilterDiv = styled.div`
  margin-bottom: 0.8em;
`;

const FilterOption = styled.button`
  border: none;
  background-color: ${(props) => props.bgColor || '#fff'};
  cursor: pointer;
  :hover{
    border-bottom: 2px solid silver;
  }
`;

const ConcludeButton = styled.button`
  // background-color: rgb(239, 239, 239);
  border: 2px solid #ffa500;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.3em;
  margin-left: 5px;
  border-radius: 0.2em;
  :hover{
    background-color: var(--green);
    color: #fff;
    border-color: var(--green);
  }
`;

const Overlay = styled.div`
  position: fixed; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: var(--violet);
`;
const ErrorBox = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  height: 20%;
  background-color: #ffffff;
  text-align: center;
  align-items: center;
  min-width: 356px;
  min-height: 262px;

  h1{
    color: red;
    font-size: 1.5em;
    margin-bottom: 2%;
  }

  p{
    font-size: 1em;
    margin-bottom: 1em;
  }

  `;
const CloseBox = styled.button`
  margin-top: 1em;
  border: none;
  background-color: var(--green);
  width: 80%;
  height: 3em;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  font-size: 1em;
`;

class Management extends React.Component{
  constructor(props) {
    super(props);
    this.db = firebase.firestore()
    this.state = {
      orders: [],
      filter: "Todos",
      statusFilter: "Todas",
      filterSelector: false,
      error: false,
      isTableOpen: true,
    }
  }

  getOrders = () => {
    this.db.collection("processos").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const order = {
          'firebase_id': doc.id,
          ...doc.data()
        };
        this.setState({ orders: this.state.orders.concat([order])})
      });
    }).catch(err => {
      this.setState({error: true})
    })
  }

  componentDidMount() {
    this.getOrders();
  }

  error = () => (
    <Overlay>
        <ErrorBox>
          <h1>Algo deu Errado!</h1>
          <p>Por favor, cheque sua conexão com a internet</p>
          <CloseBox onClick={() => this.setState({error:false})}>Fechar</CloseBox>  
        </ErrorBox>
    </Overlay>
  );

  handleConcludeOrder = (id) => {

    this.db.collection("processos").doc(id).update({open: false})
      .then(() => {
        this.setState({orders: []})
        this.getOrders();
      })
      .catch(err => {
        this.setState({err: true})
      })
  }

  orderList = (client) => {
    // Status Filter
    const statusFilter = this.state.statusFilter;
    if(client.open !== true && statusFilter === "Abertas") return
    else if(client.open !== false && statusFilter === "Concluídas") return


    const defaultOrder = () => (
      <>
        <Td>{client.client}</Td>
        <Td>{client.phone}</Td>
        <Td>{client.email}</Td>
        <Td>{client.type}</Td>
        <Td>{client.date}</Td>
      </>
    )

    const status = () => {
      switch (client.open) {
        case true:
            return (
              <ConcludeButton onClick={() => this.handleConcludeOrder(client.firebase_id)}>Concluir</ConcludeButton>
            );


        case false:
            return (
              <Td>Concluída</Td>
            );

      
        default:
          return (
            <ConcludeButton onClick={() => this.handleConcludeOrder(client.firebase_id)}>Concluir</ConcludeButton>
          );
      }
    }

    switch (this.state.filter) {
      case "Documento":
        return (
          <Tr>
            {defaultOrder()}
            <Td>{client.doc_type}</Td>
            <Td>{client.doc_reason}</Td>
            {status()}
          </Tr>
        );
      case "Trabalho":
        return (
          <Tr>
            {defaultOrder()}
            <Td>{client.age}</Td>
            <Td>{client.has_curriculum}</Td>
            {status()}
          </Tr>
        );

      case "MEI":
        return (
          <Tr>
            {defaultOrder()}
            <Td>{client.mei_type}</Td>
            <Td>{client.mei_category}</Td>
            {status()}
          </Tr>
        );
    
      default:
        return (
          <Tr>
            {defaultOrder()}
            {status()}
          </Tr>
        );
    }

  }

  filteredTh = () => {
    const defaultTh = () => (
      <>
        <Th>Cliente</Th>
        <Th>Telefone</Th>
        <Th>E-mail</Th>
        <Th>Categoria</Th>
        <Th>Entrada</Th>
      </>
    )

    const status = () => (
      <Th>Status</Th>
    );

    switch (this.state.filter) {
      case "Documento":
        return(
          <Tr>
            {defaultTh()}
            <Th>Via</Th>
            <Th>Motivo</Th>
            {status()}
          </Tr>
        );

      case "Trabalho":
        return(
          <Tr>
            {defaultTh()}
            <Th>Maior/Menor de 21</Th>
            <Th>Possuí Currículo</Th>
            {status()}
          </Tr>
        );

      case "MEI":
        return(
          <Tr>
            {defaultTh()}
            <Th>Ação</Th>
            <Th>Categoria</Th>
            {status()}
          </Tr>
        );
    
      default:
        return (
          <>
            {defaultTh()}
            {status()}
          </>
        );
    }
  }

  handleChangeFilter = (filter) => {
    this.setState({ filter: filter })
  }
  handleChangeStatusFilter = (filter) => {
    this.setState({ statusFilter: filter })
  }

  filter = () => {
    return(
    <Filter>
      <FilterButton onClick={() => this.setState({ filterSelector: !this.state.filterSelector })}>{this.state.filter}</FilterButton>
      {
        this.state.filterSelector ? (
          <FilterOptions>
            <FilterP>Serviço:</FilterP>
            <FilterDiv>
              <FilterOption
                bgColor={this.state.filter === "Todos" ? 'silver' : '#fff'}
                onClick={() => this.handleChangeFilter("Todos")}
                >Todos
              </FilterOption>
              <FilterOption
              bgColor={this.state.filter === "Documento" ? 'silver' : '#fff'}
                onClick={() => this.handleChangeFilter("Documento")}
                >Documento
              </FilterOption>
              <FilterOption
                bgColor={this.state.filter === "Trabalho" ? 'silver' : '#fff'}
                onClick={() => this.handleChangeFilter("Trabalho")}
                >Trabalho
              </FilterOption>
              <FilterOption
                bgColor={this.state.filter === "MEI" ? 'silver' : '#fff'}
                onClick={() => this.handleChangeFilter("MEI")}
                >MEI
              </FilterOption>
            </FilterDiv>
            
            <FilterP>Solicitações:</FilterP>
            <FilterDiv>
              <FilterOption
                  bgColor={this.state.statusFilter === "Todas" ? 'silver' : '#fff'}
                  onClick={() => this.handleChangeStatusFilter("Todas")}
                  >Todas
                </FilterOption>
                <FilterOption
                  bgColor={this.state.statusFilter === "Concluídas" ? 'silver' : '#fff'}
                  onClick={() => this.handleChangeStatusFilter("Concluídas")}
                  >Concluídas
                </FilterOption>
                <FilterOption
                  bgColor={this.state.statusFilter === "Abertas" ? 'silver' : '#fff'}
                  onClick={() => this.handleChangeStatusFilter("Abertas")}
                  >Abertas
                </FilterOption>
            </FilterDiv>
          </FilterOptions>
        )

          : null
        }
    </Filter>
    );
  }

  render() {
    return (
      <> 
        <Header position="relative">Central de visualização de pedidos</Header>
        {
          this.state.error ? (this.error()) : (
          <Body>

            <FilterContainer>
              <Container>
                <h1>Filtrar Por</h1>
              </Container>
              <div>
                { this.filter() } 
              </div>
            </FilterContainer>
            <Section>
              <Container>
                <h1>Solicitações:</h1>
              </Container>
    
              <TableContainer>
                <Table>
                  {
                    this.filteredTh()
                  }
                  {
                    this.state.orders.map((client) => {
                      if(this.state.filter !== "Todos" && this.state.filter !== client.type) return null;
                      return (
                        this.orderList(client)
                      );
                    })
                  }
                </Table>
              </TableContainer>
            </Section>
            <Link to="/logout"><LogoutButton>Sair</LogoutButton></Link>
          </Body>

          )
        }

        
      </>
    );
  }
}

export default Management