import React from 'react';
import Header from '../../components';
import styled from 'styled-components';
import logo from '../../assets/logo.svg';

const Body = styled.body`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: calc(100%-40px);
  min-height: 100vh;
  background-color: var(--violet);
  // padding: 109px 20px 0 20px;

  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  width: 450px;
  height: 390px;
  border-radius: 55px;
  padding: 25px;
  min-width: 375px;


  @media only screen and (max-width: 768px) {
    padding: 10% 0px 10% 0px;
    width: 80vw;
  }

`;

const Input = styled.input`
  width: 70%;
  height: 43px;
  border: 1px solid #3358A4;
  border-radius: 22px;
  margin-bottom: 10px;
  padding: 0 4% 0 4%;

  :placeholder{
    color: #7F7F7F;
    
  }
`;

const InputLabel = styled.label`
  width: 100%;
  text-align: center;
`;

const Label = styled.label`
  width: 98%;
  padding-right: 2%;
  text-align: right;

  @media only screen and (max-width: 768px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 180px;
  margin-bottom: 40px;
`;

const H1 = styled.h1`
  text-align: right;
  color: #ffffff;
  @media only screen and (max-width: 1080px) {
    text-align: center;
    margin-bottom: 30px;
  }
`;

const Button = styled.button`
  background: transparent linear-gradient(180deg, #69B42E 0%, #569A21 100%) 0% 0% no-repeat padding-box;
  border: none;
  width: 150px;
  height: 36px;
  border-radius: 18px;
  margin: 10px 0 0 20px;
  color: #ffffff;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    margin: 10px 0 0 ;
  }
`;

const ResetPassword = () => {
  return (
    <>
      <Header>Central de visualização de pedidos</Header>
      <Body>
        <H1>Central de Visualização<br/>de pedidos da Jojô</H1>
        <Form onSubmit={() => {}}>
          <Logo src={logo} />
          <InputLabel>
            <Input
              required
              type="password"
              placeholder="Nova senha"
              onChange={e => this.setState({ email: e.target.value })}
            />

            <Input
              required
              type="password"
              placeholder="Redigite a nova senha"
              onChange={e => this.setState({ password: e.target.value })}
            />
          </InputLabel>
          <Label>
            <Button týpe="submit">Entrar</Button>
          </Label>

        </Form>
      </Body>
    </>
  );

}

export default ResetPassword;