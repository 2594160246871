import styled from 'styled-components';
import Header from './Header'
import React from 'react'


export default Header;

export const Text = styled.p`
  font-size: 14px;
  letter-spacing: 0.14px;
`;

export const Line = styled.div`
  width: 100%;
  height: ${props => props.height || '3px'};
  margin: ${props => props.margin || null};
  background: var(--green);
`;

export const Circle = styled.div`
  z-index: ${props => props.zIndex ? props.zIndex : '-1'};
  position: relative;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  width : ${props => props.width || "352px"};
  height: ${props => props.height || "352px"};
  border-radius: 50%;
  border: 2px dashed var(--green);
  overflow: auto;
  -webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  @media only screen and (max-width: 997px) {
    top: ${props => props.mobTop};
    left: ${props => props.mobLeft};
    right: ${props => props.mobRight};
    width : ${props => props.mobWidth || null};
    height: ${props => props.mobHeight || null};
  }
`;

export const OldCircle = styled.div`
  z-index: ${props => props.zIndex ? props.zIndex : '-1'};
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  width : ${props => props.width || "352px"};
  height: ${props => props.height || "352px"};
  border-radius: 50%;
  border: 2px dashed var(--green);
  overflow: auto;
  -webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  @media only screen and (max-width: 947px) {
    top: ${props => props.mobTop};
    left: ${props => props.mobLeft};
    right: ${props => props.mobRight};
    width : ${props => props.mobWidth || null};
    height: ${props => props.mobHeight || null};
  }
`;

export const CircleCont = styled.div`
  z-index: ${props => props.zIndex ? props.zIndex : '-1'};
  position: absolute;
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  box-sizing: border-box;
  overflow: hidden;
  @media only screen and (max-width: 997px) {
    top: ${props => props.mobTop};
    left: ${props => props.mobLeft};
    right: ${props => props.mobRight};
  }
`;
export const backgroundCircles = () => {
  return(
    <>
      {/* Left */}
      <CircleCont top={"200px"} left={"0"} mobTop={"0px"}>
        <Circle
            top={"0px"}
            mobTop={"0px"}
            left={"-170px"}
            mobWidth={"300px"}
            mobHeight={"300px"}
          />
        <Circle
            top={"-127px"}
            mobTop={"-111px"}
            left={"71px"}
            width={"232px"}
            mobWidth={"80px"}
            height={"232px"}
            mobHeight={"80px"}
          />
      </CircleCont>


      {/* Right */}
      <CircleCont right={"0"} top={"500px"} mobTop={"750px"}>
        <Circle
            top={"0"}
            right={"-70px"}
            mobRight={"-250px"}
            width={"300px"}
            height={"300px"}
          />
          <Circle
            width={"0"}
            height={"0"}
            top={"-120px"}
            right={"20px"}
            mobRight={"-285px"}
            mobWidth={"220px"}
            mobHeight={"220px"}
          />
      </CircleCont>
    </>
  );
}