// Libs
import React, { Component } from 'react';
import styled from 'styled-components';
import profileIMG from '../../assets/Screen Shot 2020-09-11 at 16.21.48.png'
import LogoVNW from '../../assets/LogoVNW-Transparente.png'


const Align = styled.div`
  display: flex;
  justify-content: center;
`;

const Row = styled.div`
  margin: 0 10px 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-top: ${props => props.borderTop ? "1px solid #6F6F6F" : "none"};
  padding: 20px 0 20px 0;
  width: calc(100% - 20px);
  min-height: 100px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const CategoryTitle = styled.section`
  color: var(--green);
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 7px;
`;

const CategoryText = styled.p``;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 959px) {
    flex-wrap: wrap;
  }
`;

const Category = styled.div`
  margin: 10px 10px 0 10px;
  font-size: 14px;
  max-width: 465px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    flex-wrap: wrap;
  }
`;

const Img = styled.img`
  margin-right: 3px;
  margin-left: ${props => props.marginRight};
  width: ${props => props.width || "57px"};
  height: ${props => props.height || "57px"};
  border-radius: ${props => props.borderRadius || "none"};
`;

const EndRow = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 360px;
  font-size: 13px;
  text-decoration: none;

  @media only screen and (max-width: 768px){
    margin: 20px 0 20px 0;
  }
`;

const P = styled.p`
  margin-left: 10px;
  
  &:hover{
    font-weight: 500;
    padding-bottom: 2px;
    border-bottom: 5px solid var(--green);
  }
`;

class Footer extends Component {       
  categories = [
    {
      title: "Fazemos agendamento de",
      content: [
        "Identidade",
        "Carteira Profissional",
        "Título de Eleitor",
        "Receita Federal",
        "Detran",
        "INSS",
        "Carteirinha da SEAP",
        "Passaporte"
      ]
    },
    {
      title: "Outros serviços",
      content: [
        "Envio de currículo para empresas",
        "Boletim de Ocorrência",
        "Consulta ao SPC e Serasa",
        "Abertura de MEI",
        "Declaração do Imposto de Renda",
        "Declaração Anual do MEI",
        "Encerramento do MEI",
        "Segunda via do Boleto",
        "Xerox",
        "Impressões",
        "Cadastro em sites de empregos"
      ]
    },
    {
      title: "Endereço",
      content: [
        "Rua Nova Brasília, no 2, Bonsucesso 3º andar da Associação de Moradores Rio de Janeiro - RJ - Brasil"
      ]
    },
    {
      title: "Horário de funcionamento",
      content: [
        "Segunda a Sexta 09:30 ás 17:30"
      ],

    },
    {
      title: "Telefone",
      content: [
        "(21) 99209-3995",
        "(21) 3802-5060"
      ],
    },
    {
      title: "EMAIL",
      content: [
        "jojoservicos@gmail.com"
      ]
    }
  ]


  render() {
    return (
      <Align>
        <Column>
          <Row borderTop={true}>
            <Category>
              <CategoryTitle>{this.categories[0].title}</CategoryTitle>
              {this.categories[0].content.map(content => {
                return <CategoryText>{content}</CategoryText>
              })}
            </Category>
            <Category>
              <CategoryTitle>{this.categories[1].title}</CategoryTitle>
              {this.categories[1].content.map(content => {
                return <CategoryText>{content}</CategoryText>
              })}
            </Category>
            <Column>
              <Category>
                <CategoryTitle>{this.categories[2].title}</CategoryTitle>
                {this.categories[2].content.map(content => {
                  return <CategoryText>{content}</CategoryText>
                })}
              </Category>
              <Category>
                <CategoryTitle>{this.categories[3].title}</CategoryTitle>
                {this.categories[3].content.map(content => {
                  return <CategoryText>{content}</CategoryText>
                })}
              </Category>
              <Category>
                <CategoryTitle>{this.categories[4].title}</CategoryTitle>
                {this.categories[4].content.map(content => {
                  return <CategoryText>{content}</CategoryText>
                })}
              </Category>
              <Category>
                <CategoryTitle>{this.categories[5].title}</CategoryTitle>
                {this.categories[5].content.map(content => {
                  return <CategoryText>{content}</CategoryText>
                })}
              </Category>

            </Column>
          </Row>
          <Row>
            <EndRow
              target="_blank"
              href="https://www.vozdascomunidades.com.br/comunidades/complexo-do-alemao/jojo-servicos-a-empresa-que-venceu-a-crise-e-ajuda-moradores-do-alemao/?fbclid=IwAR0H_MAlo9Fxqwy8m7Qsb7AKGhBp7ZezZPLm-aSq7nLUaHeClz4rtYzo-Ko"
            > 
              <Img marginRight={"10px"} src={profileIMG} />
              <P>Notícia: “Jojô Serviços: a empresa que venceu a crise e ajuda moradores no Alemão”</P>
            </EndRow>
            <EndRow
              target="_blank"
              href="https://www.vainaweb.com.br/"
            >
              <Img width={"73px"} height={"73px"} src={LogoVNW}/>
              <P>Esse site foi desenvolvido pelo Estúdio Vai na Web</P>
            </EndRow>
          </Row>
        </Column>
      </Align>
    );
  }
}

export default Footer;