// Libs
import React from 'react';
import logo from '../assets/logo.svg';
import styled from 'styled-components';

const Container = styled.div`
  z-index: 2;
  position: ${props => props.position ? props.position : 'fixed'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 100px);
  height: 109px;
  background: #fff;
  font-size: 19px;
  color: var(--fontColor);
  padding: 0 50px 0 50px;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    width: 100%;
    padding: 0;
  }

`;

const Strong = styled.strong`
  font-size: 19px;
  font-weight: 400;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const Header = ({ children, position }) => {
  const text = children ? children : "Resolver seus problemas nunca foi tão simples.";

  return (
    <Container position={position}>
      <Strong>{text}</Strong>
      <img src={logo} alt="JOJO" />
    </Container>
  );

}

export default Header;