// Libs
import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import { nanoid } from 'nanoid';
import { format } from 'date-fns'
import firebase from 'firebase';

// Assets
import jojoProfile from '../../assets/Group 3983@2x.png'
import robot from '../../assets/robot.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 0 100px 0;
  min-height: 600px;
  background: var(--violet);
`;

const ChatStyle = styled.div`
  width: 80vw;
  max-width: 1080px;
  min-width: 360px;
  @media only screen and (max-width: 754px) {
    width: 90vw;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderTexts = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding-left: 5em;
  justify-content: space-between;

  @media only screen and (max-width: 754px) {
    padding-left: 0em;
    justify-content: center;
  }

  @media only screen and (max-width: 490px) {
    justify-content: flex-end;
    padding-right: 1em;
  }
`;

const ReloadButton = styled.button`
  border: none;
  background-color: var(--green);
  color: #fff;
  border-radius: 10px;
  @media only screen and (max-width: 754px) {
    display: none; 
  }
  cursor: pointer;
`;

const Title = styled.h2`
  color: #ffffff;
`;

const theme = {
  width: '90vw',
  background: '#ffffff',
  headerBgColor: '#ffffff',
  botBubbleColor: 'var(--primaryColor)',
  botFontColor: '#ffffff',
  userBubbleColor: 'var(--green)',
  userFontColor: '#ffffff'
}

const style = {
  zIndex: '0',
  padding: '0 20px 0 20px',
  width: 'calc(100% - 40px)',
  borderRadius: '36px'
}

const Profile = styled.img`
  z-index: 1;
  position: relative;
  top: 109px;
  left: -4em;
  width: ${props => props.width};
  height: ${props => props.width};


  @media only screen and (max-width: 754px) {
    top: 66px;
    left: 0em;
  }
`;


class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChatOpen: true,
    };

    this.db = firebase.firestore();
  }

  steps = [
    {
      "id": "init",
      "message": "Olá, sou a Jojô",
      "trigger": "services"
    },
    {
      "id": "services",
      "message": "Selecione o tipo de solução que busca encontrar:",
      "trigger": "services_options",
    },
    {
      "id": "services_options",
      "options": [
        { "value": "documents", "label": "Agendar documento", "trigger": "documents" },
        { "value": "job", "label": "Encontrar emprego", "trigger": "first_job" },
        { "value": "mei", "label": "MEI/Autônomo", "trigger": "mei" },
      ]
    },

    // Documentos 
    {
      "id": "documents",
      "message": "Documentos",
      "trigger": "doc_options"
    },
    {
      "id": "doc_options",
      "options": [
        { "value": "rg", "label": "Identidade", "trigger": "rg" },
        { "value": "titulo", "label": "Título de eleitor", "trigger": "title" },
      ]
    },
    {
      "id": "title",
      "message": "Qual é a via que você quer solicitar?",
      "trigger": "title_via"
    },
    {
      "id": "title_via",
      "options": [
        { "value": "v1_title", "label": "Primeira via", "trigger": "v1_title" },
        { "value": "v2_title", "label": "Segunda via", "trigger": "v2_title" },
      ]
    },
    {
      "id": "v1_title",
      "message": "Você vai precisar separar os seguintes documentos: 1ª via MULHER: - RG - Comprovante de residência dos 3 últimos meses 1ª via HOMEM: -RG - Comprovante de residência dos 3 últimos meses - Quitação do serviço militar (caso seja maior que 18 anos).",
      "trigger": "user_data"
    },

    {
      "id": "v2_title",
      "message": "Qual o motivo da segunda via?",
      "trigger": "v2_title_reason"
    },
    {
      "id": "v2_title_reason",
      "options": [
        { "value": "Perda/Dano", "label": "Perda ou dano", "trigger": "v1_title" },
        { "value": "Roubo", "label": "Roubo", "trigger": "v2_title_docs" },
      ]
    },
    {
      "id": "v2_title_docs",
      "message": "Você vai precisar separar os seguintes documentos: 2ª via MULHER: - RG - Comprovante de residência dos 3 últimos meses 2ª via HOMEM: -RG - Comprovante de residência dos 3 últimos meses - Quitação do serviço militar (caso seja maior que 18 anos)",
      "trigger": "user_data"
    },


    //////////
    {
      "id": "rg",
      "message": "Qual é a via que você quer solicitar?",
      "trigger": "via"
    },
    {
      "id": "via",
      "options": [
        { "value": "1via", "label": "Primeira via", "trigger": "1via" },
        { "value": "2via", "label": "Segunda via", "trigger": "2via" },
        { "value": "validade", "label": "Validade", "trigger": "validade" }
      ]
    },
    {
      "id": "1via",
      "message": "Você vai precisar dos seguintes documentos",
      "trigger": "1via_docs"
    },
    {
      "id": "1via_docs",
      "message": "- Certidão de nascimento - CPF, original e cópia",
      "trigger": "user_data"
    },
    {
      "id": "2via",
      "message": "Qual o motivo da segunda via?",
      "trigger": "2via_reason"
    },
    {
      "id": "2via_reason",
      "options": [
        { "value": "perda", "label": "Perda ou Dano", "trigger": "2via_docs" },
        { "value": "roubo", "label": "Roubo", "trigger": "2via_docs_2" },
      ]
    },
    {
      "id": "2via_docs",
      "message": "Você vai precisar separar os seguintes documentos: - Certidão de nascimento ou casamento - CPF - DUDA pago. Você vai precisar de original e cópia desses documentos.",
      "trigger": "user_data"
    },
    {
      "id": "2via_docs_2",
      "message": "Você vai precisar separar os seguintes documentos: - Certidão de nascimento ou casamento - CPF - Boletim de ocorrência. Você vai precisar de original e cópia desses documentos.",
      "trigger": "user_data"
    },
    {
      "id": "validade",
      "message": "Você vai precisar separar os seguintes documentos: - RG original - Certidão de nascimento ou casamento - CPF, original e cópia.",
      "trigger": "user_data"
    },

    // Encontrar emprego
    {
      "id": "first_job",
      "message": "Primeiro emprego?",
      "trigger": "first_job_options"
    },
    {
      "id": "first_job_options",
      "options": [
        {
          "value": "yes",
          "label": "Sim",
          "trigger": "age?"
        },
        {
          "value": "no",
          "label": "Não",
          "trigger": "age?"
        }
      ]
    },

    {
      "id": "age?",
      "message": "Qual sua idade?",
      "trigger": "age_options"
    },
    {
      "id": "age_options",
      "options": [
        { "value": "<21", "label": "Menor de 21 anos", "trigger": "is_studying?" },
        { "value": ">21", "label": "Maior de 21 anos", "trigger": "has_curriculum?" },
      ]
    },

    // /////////////////////////////////////////////////////
    {
      "id": "is_studying?",
      "message": "Está estudando ou completou o ensino médio?",
      "trigger": "is_studying_options"
    },
    {
      "id": "is_studying_options",
      "options": [
        { "value": "sim", "label": "Sim", "trigger": "has_curriculum?" },
        { "value": "não", "label": "Não", "trigger": "has_curriculum?" },
      ]
    },
    {
      "id": "has_curriculum?",
      "message": "Você possuí algum currículo?",
      "trigger": "has_curriculum_options"
    },
    {
      "id": "has_curriculum_options",
      "options": [
        { "value": "sim", "label": "Sim", "trigger": "send_to_companies" },
        { "value": "não", "label": "Não", "trigger": "curriculum_elaboration" },
      ]
    },
    {
      "id": "curriculum_elaboration",
      "message": "Posso elaborar um currículo para você!",
      "trigger": "send_to_companies2"
    },
    {
      "id": "send_to_companies",
      "message": "Eu posso envia-los para as empresas por você!",
      "trigger": "send_to_companies2"
    },
    {
      "id": "send_to_companies2",
      "message": "Você vai precisar separar os seguintes documentos:",
      "trigger": "send_to_companies_docs"
    },
    {
      "id": "send_to_companies_docs",
      "message": "- RG - Comprovante de residência - Carteira de trabalho",
      "trigger": "user_data"
    },
    {
      "id": "user_data",
      "message": "A seguir me informe alguns dados para que eu possa registrar sua solicitação:",
      "trigger": "get_name"
    },
    {
      "id": "get_name",
      "message": "Nome:",
      "trigger": "name"
    },
    {
      "id": "name",
      "user": true,
      "trigger": "get_cel",
    },
    {
      "id": "get_cel",
      "message": "Celular:",
      "trigger": "cel"
    },
    {
      "id": "cel",
      "user": true,
      "trigger": "get_email"
    },
    {
      "id": "get_email",
      "message": "E-mail:",
      "trigger": "email",
    },
    {
      "id": "email",
      "user": true,
      "trigger": "register_solicitation",
    },
    {
      "id": "register_solicitation",
      "message": "Estou registrando sua solicitação",
      "trigger": "end"
    },
    // {
    //   "id": "new_solicitation",
    //   "message": "Posso te ajudar com mais alguma coisa?",
    //   "trigger": "new_solicitation_op"
    // },
    // {
    //   "id": "new_solicitation_op",
    //   "options": [
    //     { "value": "sim", "label": "Sim", "trigger": "services" },
    //     { "value": "não", "label": "Não", "trigger": "end" },
    //   ],
    // },
    {
      "id": "end",
      "message": "Obrigada pela preferência Entro em contato com você em breve!",
      "end": true
    },

    // MEI
    {
      "id": "mei",
      "message": "Você já é registrado no MEI?",
      "trigger": "is_registeded"
    },
    {
      "id": "is_registeded",
      "options": [
        { "value": "sim", "label": "Sim", "trigger": "mei_services" },
        { "value": "não", "label": "Não", "trigger": "mei_description" },
      ]
    },
    {
      "id": "mei_services",
      "message": "O que deseja?",
      "trigger": "mei_services_op"
    },
    {
      "id": "mei_services_op",
      "options": [
        { "value": "alvara", "label": "Alvará", "trigger": "user_data" },
        { "value": "registro de vigilancia", "label": "Registro de vigilância sanitária", "trigger": "user_data" },
        { "value": "emitir nota", "label": "Emitir nota", "trigger": "user_data" },
        { "value": "negociar debito", "label": "Negociação de débitos", "trigger": "user_data" },
      ]
    },


    {
      "id": "mei_description",
      "message": "Benefícios de ser um MEI O MEI (Microempreendedor Individual) tem direito a diversos benefícios previdenciários. Para conquistar esses benefícios é necessário realizar o pagamento do DAS mensalmente e declarar anualmente os seus gastos. Declaração Anual: Esta declaração deve ser feita até 31 de Maio de cada ano. Nela é necessário informar o valor de vendas com notas fiscais e sem notas fiscais gerados no ano. Benefícios previdenciários que o MEI tem direito: Auxílio Maternidade, Auxílio doença, Auxílio reclusão, Pensão por morte, Aposentadoria por Invalidez e Aposentadoria por idade. Tudo isso pagando o DAS que é a contribuição para o Inss com um valor bem reduzido e obtendo todos os benefícios e vantagens de um autônomo. Além de todos os benefícios previdenciários, através do cnpj você pode obter diversos benefícios como ter uma conta jurídica, conseguir financiamento bancário, cartão de crédito e investir no seu negócio. Para maiores informações: Entre em contato conosco e solicite o seu orçamento",
      "trigger": "checkin_in_mei?"
    },
    {
      "id": "checkin_in_mei?",
      "message": "Você quer dar entrada no MEI?",
      "trigger": "checkin_in_mei_options"
    },

    {
      "id": "checkin_in_mei_options",
      "options": [
        { "value": "sim", "label": "Sim", "trigger": "area" },
        { "value": "não", "label": "Não", "trigger": "end_mei" },
      ]
    },
    {
      "id": "end_mei",
      "message": "Tudo bem! Obrigada pela preferência!",
      "end": true
    },
    {
      "id": "area",
      "message": "Qual área?",
      "trigger": "area_answer"
    },
    {
      "id": "area_answer",
      "user": true,
      "trigger": "user_data"
    }
  ];

  getType = (steps) => {
    if (steps.documents) {
      return 'Documento';
    }

    if (steps.first_job) {
      return 'Trabalho';
    }

    if (steps.mei) {
      return 'MEI';
    }
  }

  handleEnd = async (ev) => {
    const checkinMei = ev && ev.steps && ev.steps.checkin_in_mei_options && ev.steps.checkin_in_mei_options.value
    if(checkinMei === "não") return

    const type = this.getType(ev.steps);

    const general = {
      id: nanoid(7),
      type,
      client: ev.steps.name.value,
      phone: ev.steps.cel.value,
      email: ev.steps.email.value,
      date: format(new Date(), 'd/M/yyyy'),
      open: true,
    };

    const custom = {
      ...type === 'Documento' && (
        ev.steps.title_via ? (
          // When title
          {
            doc_type: ev.steps.title_via.value === "v1_title" ? "1ª via" : "2ª via",
            doc_reason: ev.steps.title_via.value === "v1_title" ? '-' : ev.steps['v2_title_reason'].value,
          }
         ) : (

          // When RG
          {
            doc_type: ev.steps.via.value === "1via" ? "1ª via" : "2ª via",
            doc_reason: ev.steps.via.value === "1via" ? '-' : (ev.steps.via.value === "validade" ? 'validade' : ev.steps['2via_reason'].value),
          }
         )
      ),
      ...type === 'Trabalho' && (
        {
          age: ev.steps.age_options.message,
          has_curriculum: ev.steps.has_curriculum_options.value,
          send_curriculum: true,
        }
      ),
      ...type === 'MEI' && (
        {
          mei_type: ev.value.mei_services_op,
          checkin_in_mei: ev.steps.checkin_in_mei_options.value,
          mei_category: ev.steps.are_answer.value
        }
      )
    };

    const data = {
      ...general,
      ...custom,
    };

    try {
      await this.db.collection('processos').add(data);
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <Container>
        <div>
          <ChatStyle>
            <Profile
              width="120px"
              src={jojoProfile}
            />
            <Header>
              <HeaderTexts>
                <Title>Fale com a Jojô</Title>
                <ReloadButton
                  onClick={() => {
                    this.setState({
                      isChatOpen: false,
                    },
                      () => {
                        this.setState({ isChatOpen: true })
                      })
                  }}
                >
                  Recomeçar
                </ReloadButton>
              </HeaderTexts>
            </Header>


            <ThemeProvider theme={theme}>
              {this.state.isChatOpen && (
                <ChatBot
                  handleEnd={this.handleEnd}
                  headerTitle=""
                  // speechSynthesis={{ enable: true, lang: 'pt-br' }}
                  placeholder="Digite sua mensagem"
                  style={style}
                  steps={this.steps}
                  botAvatar={robot}
                />
              )}
            </ThemeProvider>
          </ChatStyle>
        </div>
      </Container>
    );
  }
}

export default Chat;