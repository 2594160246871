// Libs
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import firebase from 'firebase';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, .5);
  z-index: 5;
`;

const Container = styled.div`
  width: 55%;
  padding: 3rem;
  border-radius: 12px;
  background-color: #FFF;
`;

const Message = styled.span`
  width: 100%;
  display: inline-block;
  margin-bottom: 1.5rem;
  color: var(--primaryColor);
  text-align: center;
  font-size: 1.5rem;
`;

const Form = styled.form`
  width: 60%;
  margin: 0 auto;
`;

const Label = styled.label`
  color: #000;
  font-size: .9rem; 
`;

const Input = styled.input`
  width: 100%;
  height: 35px;
  margin: .75rem 0;
  padding-left: 2.5%;
  border: 1px solid #3358A4;
  border-radius: 16px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width ? props.width : '100%'};
  height: 40px;
  margin-top: 1rem;
  border: none;
  border-radius: 16px;
  color: #FFF;
  cursor: pointer;
  outline: none;
  background-color: var(--primaryColor);
`;

const ConcludeButton = styled(Button)`
  width: 60%;
  margin: 0 20% 0 20%;
`

const CloseButton = styled.button`
  width: 2em;
  height: 2em;
  float: right;
  border-radius: 50%;
  border: none;
  cursor: pointer;
`;

const Error = styled.span`
  color: red;
  font-size: .9rem;
`;

class Modal extends Component {
  state = {
    isLoading: false,
    email: {
      send: false,
      message: ''
    },
    error: '',
  };

  handleClose = () => {
    this.props.setModal({
      isOpen: false,
      type: ''
    });
  }

  handleLogin = () => {
    this.setState({
      isLoading: true,
      error: ''
    });

    firebase.auth().signInWithEmailAndPassword(
      this.email.value.trim(),
      this.password.value.trim()
    )
      .then((user) => {
        this.setState({
          isLoading: false,
          error: ''
        });

        this.props.handleAuth(
          {
            isAuthenticated: true,
          }
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: 'Dados inválidos'
        });
      });
  }

  handleCreateAccount = () => {
    this.setState({
      isLoading: true,
      error: ''
    });

    firebase.auth().createUserWithEmailAndPassword(
      this.email.value.trim(),
      this.password.value.trim()
    )
      .then((user) => {
        this.setState({
          isLoading: false,
          error: ''
        });

        this.props.handleAuth(
          {
            isAuthenticated: true,
          }
        );
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          this.setState({
            isLoading: false,
            error: 'Não foi possível criar sua conta'
          });
        }
      });
  }

  handleResetPasswd = () => {
    this.setState({
      isLoading: true,
      error: ''
    });

    firebase.auth().sendPasswordResetEmail(
      this.email.value.trim(),
    )
      .then((user) => {
        this.setState({
          isLoading: false,
          error: ''
        });

        this.setState({
          email: {
            send: true,
            message: 'Enviamos um link de redefinição de senha para o email informado!'
          }
        })

      })
      .catch((error) => {
        let errorMessage;
        console.log(error)
        switch(error.code) {
          case "auth/user-not-found":
            errorMessage = "Email não encontrado"
            break

          default:
            errorMessage = 'Não foi possível mudar sua senha'
            break
        }

        this.setState({
          isLoading: false,
          error: errorMessage
        });
      });
  }

  validateFields = () => {
    const { modal } = this.props;

    let validation = false;

    //if (modal.type === 'signup' || modal.type === 'resetpasswd') {
    if (modal.type === 'signup') {
      const password = this.password.value.trim();
      const match = this.passwordMatch.value.trim();

      validation = password === match;
    } else {
      validation = true;
    }

    return validation;
  }

  textButton = () => {
    const { modal } = this.props;
    
    switch(modal.type){
      case 'signin':
        return 'Entrar'

      case 'signup':
        return 'Criar conta'

      case 'resetpasswd':
        return 'Enviar Link';

      default: 
        return modal.type
    }
  }

  textTitle = () => {
    const { modal } = this.props;
    switch(modal.type){
      case 'signin':
        return 'Acesse o chat'

      case 'signup':
        return 'Faça seu cadastro'

      case 'resetpasswd':
        const { email } = this.state;
        const text = email.send ? null : 'Mudar a Senha';
        return text

      default: 
        return modal.type
    }
  }

  handleSubmit = (ev) => {
    const { modal } = this.props;

    ev.preventDefault();

    if (this.validateFields()) {
      switch(modal.type) {
        case 'signin':
          this.handleLogin();
          break;
        case 'signup':
          this.handleCreateAccount();
          break;
        case 'resetpasswd':
          this.handleResetPasswd();
          break;
        default:
          return null;
      }
    } else {
      this.setState({
        error: 'As senhas não coincidem',
      })
    }
  }

  renderButton = () => {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <Button disabled>
          Loading...
        </Button>
      );
    }

    const { email } = this.state;
    if(email.send) return

    return (
      <Button>
        {this.textButton()}
      </Button>
    );
  }

  emailLabel = () => (
    <Fragment>
      <Label for="email">Email:</Label>
      <Input
        required
        ref={(node) => {
          this.email = node;
        }}
        id="email"
        type="email"
      />
    </Fragment>
  ) 

  render() {
    const { modal } = this.props;
    const { error } = this.state;
    const { email } = this.state;

    return (
      <Overlay
        onClick={this.handleClose}
      >
        <Container
          onClick={(ev) => {
            ev.stopPropagation();
          }}
        >
          <CloseButton onClick={this.handleClose} >X</CloseButton>
          <Message>
            { this.textTitle() }
          </Message>
          <Form
            onSubmit={this.handleSubmit}
          >
            { 
              modal.type === 'resetpasswd' ? (
                email.send ? <Message>{email.message}</Message> : this.emailLabel()
              ) : this.emailLabel()
            }

            {modal.type !== 'resetpasswd' && (<Label for="password">Senha:</Label>)}

            {modal.type !== 'resetpasswd' && ( 
              <Input
                required
                ref={(node) => {
                  this.password = node;
                }}
                id="password"
                type="password"
              />
            )}
            {modal.type === 'signup' && (
              <Fragment>
                <Label form="match-password">Repita a senha:</Label>
                <Input
                  required
                  ref={(node) => {
                    this.passwordMatch = node;
                  }}
                  id="match-password"
                  type="password"
                />
              </Fragment>
            )}
            {error && (
              <Error>
                {error}
              </Error>
            )}
            {this.renderButton()}
          </Form>
          { email.send ? <ConcludeButton onClick={this.handleClose}>Ok</ConcludeButton> : null }
        </Container>
      </Overlay>
    )
  }
}

export default Modal;