import React, { Component } from 'react';
import Header from '../../components';
import logo from '../../assets/logo.svg';
import styled from 'styled-components';
import {
  Redirect
} from 'react-router-dom';
import firebase from 'firebase';

const Body = styled.body`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background: transparent linear-gradient(180deg, #2E7BB4 0%, #306BAD 12%, #3260A8 32%, #3358A4 100%) 0% 0% no-repeat padding-box;


  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 35%;
  height: 310px;
  border-radius: 55px;
  padding: 2%;
  min-width: 375px;

  @media only screen and (max-width: 768px) {
    padding: 5% 0px 5% 0px;
    width: 80vw;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  height: 100%;
  margin: 0 auto;
`;

const Input = styled.input`
  width: 90%;
  height: 43px;
  border: 1px solid #3358A4;
  border-radius: 22px;
  margin-bottom: 5px;
  padding: 0 4% 0 4%;

  :placeholder{
    color: #7F7F7F;
    
  }
`;

const InputLabel = styled.label`
  width: 100%;
  text-align: center;
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding: 0;
    display: flex;
  }
`;

const Logo = styled.img`
  width: 180px;
  margin-bottom: 40px;
`;

const H1 = styled.h1`
  text-align: right;
  color: #ffffff;
  @media only screen and (max-width: 1080px) {
    text-align: center;
    margin-bottom: 30px;
  }
`;

const Button = styled.button`
  background: transparent linear-gradient(180deg, #69B42E 0%, #569A21 100%) 0% 0% no-repeat padding-box;
  border: none;
  width: 150px;
  height: 36px;
  border-radius: 18px;
  color: #ffffff;
  cursor: pointer;
  margin: 10px 0 20px 0;

  @media only screen and (max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;

const Error = styled.span`
  display: inline-block;
  color: red;
  font-size: 0.9em;
`;

class Login extends Component {
  state = {
    redirect: false,
    email: '',
    password: '',
    loading: false,
    error: false,
    whitelist: [
      'jojoservicos@gmail.com',
      'ygorfonseca@1sti.com.br'
    ]
  };

  componentDidMount() {
    const creds = window.localStorage.getItem('jojo_creds');

    if (creds) {
      this.setState({
        redirect: true,
      });
    }
  }

  handleLocalStorage = (data) => {
    window.localStorage.setItem('jojo_creds', JSON.stringify({
      refreshToken: data.user.refreshToken
    }));

    this.setState({
      redirect: true,
    });
  }

  handleSubmit = (ev) => {
    ev.preventDefault();

    this.setState({
      loading: true,
    });

    const { email, password, whitelist } = this.state;

    if (!whitelist.includes(email)) {
      this.setState({
        error: true,
        loading: false,
      });

      return;
    }

    firebase.auth().signInWithEmailAndPassword(email, password).then((data) => {
      this.setState({
        loading: false,
        error: false,
      });

      this.handleLocalStorage(data);
    }).catch((error) => {
      this.setState({
        error: true,
        loading: false,
      });
    });
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return (
        <Redirect
          to='/admin'
        />
      );
    }
  }

  render() {
    return (
      <>
        <Header position="relative">Resolver seus problemas nunca foi tão simples.</Header>
        <Body>
          <H1>Central de Visualização<br/>de pedidos da Jojô</H1>
          <Form onSubmit={this.handleSubmit}>
            <InnerContainer>
              <Logo src={logo} />
              <InputLabel>
                <Input
                  type="email"
                  value={this.state.email}
                  placeholder="email"
                  required
                  onChange={(ev) => {
                    this.setState({
                      email: ev.target.value,
                    })
                  }}
                />
                <Input
                  type="password"
                  value={this.state.password}
                  placeholder="senha"
                  required
                  onChange={(ev) => {
                    this.setState({
                      password: ev.target.value,
                    })
                  }}
                />
              </InputLabel>
              {this.state.error ? (
                <Error>
                  Usuário ou senha inválidos
                </Error>
              ) :
              (
                <Error>
                  
                </Error>
              )}
              <Label>
                <Button
                  disabled={this.state.loading}
                >
                  {this.state.loading 
                    ? 'Loading...'
                    : 'Entrar'
                  }
                </Button>
                {/* <Link to="/admin/reset_password">Esqueceu a senha?</Link> */}
              </Label>
            </InnerContainer>
          </Form>
          {this.renderRedirect()}
        </Body>
      </>
    );
  }
}

export default Login;